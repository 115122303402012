.network-container{
    width: 60% !important;
    border:1px solid #eae9e9;
    border-radius: 5px;
    margin-top: 5px;
}
.network-Item{
    box-shadow: none !important;
    border-radius: 0px !important;
    height: 62px !important;
}
.color-title-bg{
 background-color: #e9f7fb !important;
 border-bottom: 1px solid #eae9e9;
}
.color-values-bg{
background-color: #dde4ff !important;
border-bottom: 1px solid #d0d0d0;
font-weight: 600 !important;
color: #1d1c1c;
}
.button-container{
    width: 60%;
    display: flex;
    justify-content: end;
}
.display-error-text{
    color: red;
    border: 1px solid red;
    padding: 10px;
}
.showERR{
    border: 2px solid red !important;
    border-radius: 5px;
}
.css-19kzrtu{
    padding: 24px 5px !important;
}