body{
    min-height: auto !important;
    background: #e5e5e5;
}
.login-page .login-panel .form .MuiTextField-root{
    box-shadow:none !important;
}

.login-page .login-panel .form{
    margin-top: 36px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
}
.login-page .login-panel{
    background: none !important;
}
.login-page .login-panel .logo{
    margin-top: 20px;
}
.app-name-font{
    font-weight: 600 !important;
    padding-top: 25px;
}
.select-form-cont{
    width:98%;
}
.titles{
   padding-top: 15px;
   font-weight: 600 !important;
}