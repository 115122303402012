.login-page {
  // background: #e5e5e5 !important;
  position: relative;
}
.app{
  padding:'10px'
}
// body {
//   font-family: Arial, Helvetica, sans-serif;
// }
.app-name-font {
  font-weight: bolder !important
}

//overriding amplify stylings below

.amplify-button[data-variation='primary'] {
  background: #00b0f0;

  &:hover {
    background-color: #0e90c7;
  }
}

.amplify-field-group :not(:last-child) .amplify-input {
  padding-left: 60px;
}

[data-amplify-authenticator] [data-amplify-router] {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.data-amplify-container {
  background: #fff;
}

.login-panel {
  width: 480px;
  background: #fff !important;
  margin: auto;
}

.afterLogin {
  background: #fff;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.detail-card-header {
  background-color: grey;
  border-radius: 5px;
  color: white;
  padding-left: 10px;
  height: 50px;
  text-align: left;
}
.MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone {
  max-height: none !important;
}

.MuiDataGrid-cell {
  max-height: none !important;
  overflow: auto;
  white-space: initial !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.css-ah0e1x-MuiPaper-root{
  box-shadow: none !important;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'browse';
  padding: 10px 8px;
  color: #1976d2;
  outline: none;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 1rem;
}

.custom-file-input-feed::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input-feed::before {
  content: 'Import';
  color: white;
  outline: none;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 1rem;
}
input[type='file'] {
  color: rgba(0, 0, 0, 0);
  width: 80px;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper, .css-uhb5lp{
  max-width: 100% !important;
}
.css-1f2xuhi-MuiDrawer-docked .MuiDrawer-paper, .css-1l8j5k8{
  background: #04508f !important; 
}