body{
    min-height: auto !important;
}

.login-page .login-panel .form .MuiTextField-root{
    box-shadow:none !important;
}
.TextButton{
  background: none !important;
  color: #00b0f0 !important;
  text-transform: none !important;
}
.login-page .login-panel .form{
    margin-top: 36px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
}
.login-page .login-panel{
    background: none !important;
}
.login-page .login-panel .logo{
    margin-top: 20px;
}

[data-amplify-authenticator] {
    display: flex !important;
    margin: 0px auto 0px auto;
    text-align: center;
}
[data-amplify-authenticator], [data-amplify-container]{
    background: #fff !important;
    border-radius: 5px !important;
}
[data-amplify-authenticator] ,[data-amplify-router]{
    border:none !important;
    box-shadow: none !important;
}
[data-amplify-authenticator] [data-amplify-form]{
    padding: 25px !important;
}
[data-amplify-footer]{
    padding-bottom: 10px !important;
    width: 44% !important;
}
.amplify-label{
    display: none !important;
}
.amplify-field-group__field-wrapper{
    text-align: left !important;
}
.app-name-font{
    font-weight: 600 !important;
    padding-top: 25px;
}
.amplify-button--primary{
    background: #00b0f0 !important;
}
.logo{
    margin-top:45px !important;
}
.select-form-cont{
    width:98%;
}
.amplify-field-group :not(:last-child) .amplify-input{
    padding-left: 15px !important;
}
.css-1f2xuhi-MuiDrawer-docked .MuiDrawer-paper, .css-1l8j5k8{
    background: #04508f !important; 
}
.List-Header{
    color: #fff;
    font-weight: 800;
    padding: 10px;
    display: flex;
    cursor: pointer;
}
.menu-link-item{
    color: #a5bacd !important;
    text-decoration: none !important;
    font-size: 13px;
    padding-left: 10px;
}
.left-logo{
    height: 100px;
    width: 100px;
    position: absolute;
    padding: 10px 0px;
    left: 10px;
}
.css-1k455el{
    min-height: 120px !important;
}
.logout-cont{
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
}
.Logout{
    color: #a5bacd !important;
    text-transform: none !important;  
}
.logout-icon{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 18px !important;
    margin-right: 15px;
}
.login-panel{
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 20px;
}
.sso-cont{
    display: block;
    position: absolute;
    width: 41%;
    right: 0px;
    bottom: 30px;
}
.SSO-button{
    background: none !important;
    border: none !important;
    font-weight: normal !important;
    font-size: 14px !important;
    color: #047d95 !important;
    padding: 5px 10px !important;
    border-radius: 5px;
}
.SSO-button:hover{
    background-color: #e9f9fc !important;
    color: #005566 !important;
}
