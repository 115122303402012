body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.qFontSize{
  font-size: 14px;
  margin:0
}
.qFontBold{
  font-weight: bold;
  font-size: 13px;
  margin: 0;
}
.overlay-image {
  position: relative;
  width: 400px !important;
  height: 600px !important;
  background-size: 100% 100% !important;
}
.toolbar-height{
  min-height: 45px !important;

}
.label-class{
  padding-top: 5px;
}
.pallet-labels{
  font-size:2.0em !important
}
.padding-right-class{
  padding-right: 8px;
}

