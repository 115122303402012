.css-1f2xuhi-MuiDrawer-docked .MuiDrawer-paper, .css-1l8j5k8{
    background: #04508f !important; 
}
.List-Header{
    color: #fff;
    font-weight: 800;
    padding: 10px;
    display: flex;
    cursor: pointer;
}
.menu-link-item{
    color: #a5bacd !important;
    text-decoration: none !important;
    font-size: 13px;
    padding-left: 10px;
}
.left-logo{
    height: 100px;
    width: 100px;
    position: absolute;
    padding: 10px 0px;
    left: 10px;
}
.css-1k455el{
    min-height: 120px !important;
}
.logout-cont{
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
}
.Logout{
    color: #a5bacd !important;
    text-transform: none !important;  
}
.logout-icon{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 18px !important;
    margin-right: 15px;
}