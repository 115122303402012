.uploadContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height:  auto;
    min-height: 35vh !important;
    width: 73vh !important;
    border: 1px dashed blue;
    border-radius: 5px;
    margin-top: 1rem;
  }
  .itemStyle{
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  :disabled{
    opacity: 0.2;
  }
  .css-d3ejd1{
    box-shadow: none !important;
  }
  .confirm-container{
    width:100%;
    height: auto;
    box-shadow: 2px 2px 2px #ccc;
    padding: 10px;
    border: 1px solid #ccc;
  }
  .importBtn{
    padding: 14px !important;
    margin-top: -9px !important;
    margin-left: 5px !important;
  }